
import React, { createContext, useContext, useState } from 'react';
import { ChildrenProps, Environment, IBlockedSystem, IEmployeeLogged, IGlobalProvider, ITour } from 'src/Components/Interfaces';

export const SharedStateGlobalContext = createContext<IGlobalProvider>(null);
export const SharedStateGlobalProvider = ({ children }: ChildrenProps) => {
    const [environmentVariableSystem, setEnvironmentVariableSystem] = useState<Environment>(Environment.PROD);
    const [blockedSystemNotPaid, setBlockedSystemNotPaid] = useState<IBlockedSystem>({
        blockedSystem: false,
        alertSystem: false,
        parameterToGet: ""
    });
    const [messagePhoneSupport, setMessagePhoneSupport] = useState<string>("");
    const [employeeLogged, setEmployeeLogged] = useState<IEmployeeLogged>({
        id: null,
        fedexId: null,
        fullName: null,
    });
    const [tour, setTour] = useState<ITour>({
        startTour: false,
        run: false,
        stepIndex: 0,
        steps: []
    });

    return (
        <SharedStateGlobalContext.Provider value={{ environmentVariableSystem, setEnvironmentVariableSystem, blockedSystemNotPaid, setBlockedSystemNotPaid, messagePhoneSupport, setMessagePhoneSupport, employeeLogged, setEmployeeLogged, tour, setTour }}>
            {children}
        </SharedStateGlobalContext.Provider>
    );
};

export const useSharedStateGlobal = () => {
    const context = useContext(SharedStateGlobalContext);
    if (!context) {
        throw new Error('check if the global provider is in the parent component of the component you are trying to use the context in.');
    }
    return context;
};