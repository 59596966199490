import EventEmitter from "events";
import { IEventConfirmModal, ToastMsgProps } from "src/Components/Interfaces";

class MyEventEmitter extends EventEmitter {
  emitToastSuccessDefault() {
    return this.emit("toast");
  }
  emitToastCustom(data: ToastMsgProps) {
    return this.emit("toast", data);
  }
  emitToastError(data: string[]) {
    return this.emit("toastError", data);
  }
  emitToastClear(data?: number) {
    return this.emit("toastClear", data);
  }

  emitOpenConfirmModalSaveRecord(typeConfirmModal: IEventConfirmModal) {
    return this.emit("openConfirmationEventModal", typeConfirmModal);
  }
  emitSaveRegistry() {
    return this.emit("saveRegistry");
  }
  emitCancelRegistry() {
    return this.emit("cancelRegistry");
  }
}

const eventEmitter = new MyEventEmitter();

export default eventEmitter;
