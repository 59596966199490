/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import TokenFunctionsHelper from "src/helpers/Functions/TokenFunctionsHelper";
import eventEmitter from "src/Event";

const REQUEST_CANCELLED = 20;

const useFetch = () => {
  const { clearStorageAndExitSystem, checkIfHaveTokenAndIsCurrentVersion } =
    TokenFunctionsHelper();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorToast, setErrorToast] = useState(null);
  const [loading, setLoading] = useState(false);

  const controller = new AbortController();

  const request = React.useCallback(async (url, options, isAbort = true) => {
    let response;
    let json;
    let messageError;
    try {
      clearAllToMakeNewRequest(isGet(options));
      options = await checkIfHaveTokenAndIsCurrentVersion(options);
      await fetch(
        url,
        isAbort
          ? {
              ...options,
              signal: controller.signal,
            }
          : { ...options }
      ).then((resp) => {
        validationResponse(resp);
        response = resp;
      });
      json = await response.json();
      if (!response.ok) {
        const errors =
          json.errors.Message || json.errors.Messages || json.errors;
        var listErrors = extractErrorMessages(errors);
        eventEmitter.emitToastError(listErrors);
      }
    } catch (err) {
      json = null;
      messageError = err;
      if (checkResponseStatusCode(err))
        eventEmitter.emitToastCustom({
          title: "Error",
          text: "Please contact the support@mygroundforce.com",
          color: "toast-danger",
        });
    } finally {
      setData(json);
      setLoading(false);
      if (!response) setError(messageError);
    }
    if (response) return { response, json };
    else return { response: { ok: false } };
  }, []);

  const exportRequest = React.useCallback(
    async (url, options, nameExport, typeExport, print = false) => {
      let response;
      let messageError;

      try {
        clearAllToMakeNewRequest(isGet(options));
        options = await checkIfHaveTokenAndIsCurrentVersion(options);
        await fetch(url, options).then((resp) => {
          validationResponse(resp);

          resp
            .blob()
            .then((blob) => URL.createObjectURL(blob))
            .then((url) => {
              const exportActions = {
                exportExcel: () => window.open(url),
                exportPdf: () => {
                  if (print) {
                    const pdfFrame = document.querySelector("#pdf-frame");
                    pdfFrame.src = url;
                    pdfFrame.onload = () => {
                      pdfFrame.contentWindow.print();
                    };
                  } else {
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${nameExport}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  }
                },
              };

              const action = exportActions[typeExport];
              if (action) action();

              response = resp;
              URL.revokeObjectURL(url);
            });
        });
      } catch (err) {
        messageError = err;
      } finally {
        setLoading(false);
        if (!response) setError(messageError);
      }
    },
    []
  );

  function isGet(options) {
    return options.method === "GET";
  }

  function validationResponse(resp) {
    if (resp.status === 401) {
      clearStorageAndExitSystem();
    }
    if (resp.status.toString().startsWith("5")) {
      eventEmitter.emitToastCustom({
        title: "Server error",
        text: "Server error. Please contact the support@mygroundforce.com",
        color: "toast-danger",
      });
    }
  }

  function checkResponseStatusCode(error) {
    return error?.code !== undefined && error?.code !== REQUEST_CANCELLED;
  }

  function extractErrorMessages(errors) {
    if (!errors) return [];
    const flatErrors = Object.values(errors).flat(2);
    const errorMessages = flatErrors.filter(
      (error) => typeof error === "string"
    );
    return errorMessages;
  }

  function clearAllToMakeNewRequest(isGET = false) {
    if (!isGET) eventEmitter.emitToastClear();
    setData(null);
    setError(null);
    setErrorToast(null);
    setLoading(true);
  }

  useEffect(() => {
    return () => controller.abort();
  }, [request]);

  return {
    data,
    loading,
    error,
    errorToast,
    request,
    exportRequest,
  };
};

export default useFetch;
